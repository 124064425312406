<template>
  <ComponentPricing :pricingPlanNameProp="pricingPlanName" :pricingPlanDescriptionProp="pricingPlanLegend" :subscriptionButtonNameProp="subscriptionButtonName" :pricingPlanPriceProp="pricingPlanPrice" :stripeProductNamesProp="stripeProductNames" :pricingIconProp="pricingIcon" :successEndpointProp="successEndpoint" :cancelEndpointProp="cancelEndpoint" />
</template>

<script>
export default {
  name: 'SubscribeSection',
  components: {
    ComponentPricing: () => import('./ComponentPricing.vue')
  },
  data: () => ({
    pricingPlanName: ['ProInsight Membership'],
    pricingPlanLegend: ['Monthly Subscription'],
    pricingPlanPrice: [99],
    stripeProductNames: ['solo'],
    pricingIcon: 'mdi-account-circle',
    subscriptionButtonName: 'Pay now',
    successEndpoint: '/download?sessionId={CHECKOUT_SESSION_ID}',
    cancelEndpoint: '/subscribesolo'
  })

  // data: () => ({
  //   pricingPlanName: ['Family Office Certification', 'ProInsight Membership'],
  //   pricingPlanLegend: ['Certification', 'Monthly Subscription'],
  //   pricingPlanPrice: [499, 99],
  //   stripeProductNames: ['certification', 'solo'],
  //   pricingIcon: 'mdi-account-circle',
  //   subscriptionButtonName: 'Pay now',
  //   successEndpoint: '/download?sessionId={CHECKOUT_SESSION_ID}',
  //   cancelEndpoint: '/subscribesolo'
  // })
}
</script>
